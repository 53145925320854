import { ethers } from "ethers";
import {
  getDelegateRequestMessage,
  postDelegateRequest,
} from "~/services/tasks";
import { getPermissionsRequested } from "~/services/company";
import blockchainService from "@/services/blockchain";
import delegatesService from "@/services/delegates";
import { useSessionStore } from "~~/store/session";
import { useRiseIdStore } from "~~/store/riseId";
import { useWalletStore } from "~~/store/wallet";

export default {
  data() {
    return {
      loadingDelegates: false,
    };
  },
  computed: {
    riseId() {
      const sessionStore = useSessionStore();
      return sessionStore.riseId;
    },
    riseIdOwner() {
      const riseIdStore = useRiseIdStore();
      return riseIdStore.owner;
    },
    delegates() {
      const riseIdStore = useRiseIdStore();
      return riseIdStore.delegates;
    },
  },

  async mounted() {
    this.getDelegates();
    this.getRiseIdOwner();
  },

  methods: {
    async getDelegates(refresh = false) {
      this.loadingDelegates = true;

      try {
        if (!this.delegates || refresh) {
          const riseIdStore = useRiseIdStore();
          await riseIdStore.getDelegates();
        }
      } finally {
        this.loadingDelegates = false;
      }
    },

    async getRiseIdOwner(refresh = false) {
      if (!this.riseIdOwner || refresh) {
        const sessionStore = useSessionStore();
        await sessionStore.me();
        const riseIdStore = useRiseIdStore();
        await riseIdStore.getOwner(this.riseId);
      }
    },

    async addDelegate(name, address, type = "other", owner = null) {
      if (!name) {
        this.$toastError("Provide a name for the wallet");
        return;
      }

      if (!ethers.utils.isAddress(address)) {
        this.$toastError("Provide a valid address");
        return;
      }

      if (this.delegates?.some((d) => d.address === address)) {
        this.$toastError("This address already is a delegate");
        return;
      }

      this.loadingDelegates = true;
      try {
        const walletStore = useWalletStore();

        if (!walletStore.isActive) await walletStore.connectWallet();
        const wallet = await walletStore.walletAddress;

        if (this.riseIdOwner !== wallet) {
          this.$toastError("Wallet connected must be the owner");
          return;
        }

        const data = (
          await blockchainService.getDynamic("riseid/add-delegate", {
            rise_id: this.riseId,
            delegate: address,
            wallet,
          })
        ).data;

        // ;
        const signature = await walletStore.signTypedData(data);

        await blockchainService.postDynamic(
          "riseid/add-delegate",
          {
            name,
            address,
            type,
            owner,
            signature,
            request: data.message,
          },
          {
            rise_id: this.riseId,
            delegate: address,
            wallet,
          }
        );

        this.$toastSuccess("Delegate added successfully.");

        await this.getDelegates(true);
      } catch (e) {
        this.$sentryCaptureException(e);
      } finally {
        this.$loader.hide();
        this.loadingDelegates = false;
      }
    },

    async transferOwnership(address) {
      this.loadingDelegates = true;
      try {
        const walletStore = useWalletStore();
        if (!walletStore.isActive) await walletStore.connectWallet();
        const wallet = await walletStore.walletAddress;
        const request = (
          await blockchainService.putTransferOwnership(
            this.riseId,
            address,
            wallet
          )
        ).data;
        const signature = await walletStore.signTypedData(request);

        await blockchainService.postTransferOwnership(this.riseId, {
          newOwnerAddress: address,
          wallet,
          signature,
          request: request.message,
        });
        this.$toastSuccess("You have transferred your RiseID ownership");
        await this.getRiseIdOwner(true);
      } catch (e) {
        console.error("Failed to transfer ownership", e);
        this.$sentryCaptureException(e);
      } finally {
        this.$loader.hide();
        this.loadingDelegates = false;
      }
    },

    async requestDelegate() {
      this.loadingDelegates = true;
      try {
        const walletStore = useWalletStore();
        if (!walletStore.isActive) await walletStore.connectWallet(false);
        const wallet = await walletStore.walletAddress;

        const message = await getDelegateRequestMessage(wallet);
        const signature = await walletStore.signMessage(message);
        const company = this.sessionStore.company;
        await postDelegateRequest({
          company: company.id,
          message,
          type: walletStore.walletType.toLowerCase(),
          signature,
          delegateAddress: wallet,
        });
        this.requestedPermissions = await getPermissionsRequested(company.id);
        this.$toastSuccess("Delegate address request sent");
      } catch (e) {
        this.$sentryCaptureException(e);
        console.log("Failed to send Delegate Address Request", e);
      } finally {
        this.$loader.hide();
        this.loadingDelegates = false;
      }
    },

    async deleteDelegate(address) {
      if (!this.delegates?.some((e) => e.address === address)) {
        this.$toastError("This address isn't a delegate anymore");
        return;
      }

      this.loadingDelegates = true;
      try {
        const walletStore = useWalletStore();
        if (!walletStore.isActive) await walletStore.connectWallet();
        const wallet = await walletStore.walletAddress;

        if (this.riseIdOwner !== wallet) {
          this.$toastError("Wallet connected must be the owner");
          return;
        }

        const data = (
          await blockchainService.getDynamic("riseid/remove-delegate", {
            rise_id: this.riseId,
            delegate: address,
            wallet,
          })
        ).data;
        const signature = await walletStore.signTypedData(data);
        const request = data.message;

        await blockchainService.postDynamic(
          "riseid/remove-delegate",
          { signature, request },
          {
            rise_id: this.riseId,
            delegate: address,
            wallet,
          }
        );

        this.$toastSuccess("Delegate removed successfully.");

        await this.getDelegates(true);
      } catch (e) {
        this.$sentryCaptureException(e);
      } finally {
        this.$loader.hide();
        this.loadingDelegates = false;
      }
    },

    async renameDelegate(delegate) {
      this.loadingDelegates = true;
      try {
        await delegatesService.updateDelegate(delegate.id, delegate);
        this.$toastSuccess("Delegate updated successfully");
      } catch (e) {
        this.$sentryCaptureException(e);
      } finally {
        this.$loader.hide();
        this.loadingDelegates = false;
      }
    },
  },
};
